.App {
  text-align: center;
  background-color: transparent;
 
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.hover tr {
  cursor: pointer;
}

.App-header {
  background-color: #856715;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.table{
  padding-left: calc(15px + 45 * (100vw - 481px)/ 1320);
  padding-bottom: calc(25px + 25 * (100vw - 481px)/ 1320);
  padding-top: calc(25px + 25 * (100vw - 481px)/ 1320);
  padding-right: calc(15px + 45 * (100vw - 481px)/ 1320);
}
.table_match{
  padding-left: calc(15px + 45 * (100vw - 481px)/ 1320);
  padding-bottom: calc(25px + 25 * (100vw - 481px)/ 1320);
  padding-right: calc(15px + 45 * (100vw - 481px)/ 1320);
}

.basic_detail {
  text-align: left;
  padding-left: 10%;
  padding-top: 2%;
}

.event{
  padding-left: calc(15px + 45 * (100vw - 481px)/ 1320);
  
}

.decorative_bar{
  background-color: rgba(0, 0, 0, 0);
background-position-x: 0%;
background-position-y: 0%;
background-repeat: repeat;
background-attachment: scroll;
background-image: linear-gradient(to right, rgb(245, 130, 42) 0px, rgb(171, 33, 83) 100%);
background-size: auto;
background-origin: padding-box;
background-clip: border-box;
height: calc(5px + 5 * (100vw - 481px)/ 1320)
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.event_card{
  margin-top: 2%;
  margin-left: 5%;
}
.view_event{
  text-align: center;
  margin-left: 5%;
}
.view_event_form{
  text-align: center;
  word-wrap:break-word;
}
.athlete_card{
  flex-direction: row;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
}
@media  (max-width: 600px) {
  .athlete_card {
    flex-direction: column;
    align-items: center;
    display: flex;
  }
}
.athlete_box{
  margin-top: 2%;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 2%;

}
.parent {
  display: flex;
  flex-direction: row;
}
@media  (max-width: 600px) {
  .parent {
    flex-direction: column;
    align-items: center;
    display: flex;
  }
}
@media  (min-width: 600px) {
  .child {
    width: 50%;
    height: 80vh;
    align-items: center;
  }
}
.full_height{
  height: 100vh;
}

