.app_bar{
    background-color:#fa8072;
   
}

.player-wrapper {
    position: relative;
    padding-top: 35% ;/* Player ratio: 100 / (1280 / 720) */
  }
  
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }